import i18next from 'i18next';
import {
  FETCH_LANGUAGE_DATA_BEGIN,
  FETCH_LANGUAGE_DATA_ERROR,
  FETCH_LANGUAGE_DATA_SUCCESS,
  RESET_LANGUAGE_DATA,
  SET_LOCATION_ID,
  SET_LOCATION_CODES,
} from "../actions/language.actions";

export const INITIAL_STATE = {
  loading: false,
  languageData: {},
  defaultData: {},
  detailsData: {},
  location: null,
  code: null,
  subCode: null,
};

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LANGUAGE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LANGUAGE_DATA_SUCCESS:
      if (action.meta.locationId === 0) {
        return {
          ...state,
          loading: false,
          defaultData: action.payload,
          languageData: {
            ...action.payload,
            ...(state.detailsData || {}),
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          detailsData: action.payload,
          languageData: {
            ...(state.defaultData || {}),
            ...action.payload,
          },
        };
      }
    case FETCH_LANGUAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_LANGUAGE_DATA:
      i18next.removeResourceBundle('en', 'LANGUAGE_LABELS_DETAILS');
      i18next.removeResourceBundle('fr', 'LANGUAGE_LABELS_DETAILS');
      
      return {
        ...state,
        detailsData: INITIAL_STATE.detailsData,
        languageData: state.defaultData || {},
        code: null,
        subCode: null,
      };
    case SET_LOCATION_ID:
      return {
        ...state,
        location: action.payload ?? null,
      };
    case SET_LOCATION_CODES:
      return {
        ...state,
        code: action.payload.Code ?? null,
        subCode: action.payload.SubCode ?? null,
      };

    default:
      return state;
  }
};

export default languageReducer;
