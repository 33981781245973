import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import BugsnagManager from './BugsnagManager';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector) 
  .init({
    fallbackLng: 'en', 
    ns: ['common', 'errors'], 
    defaultNS: 'common', 
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    saveMissing: true,
    missingKeyHandler: (lng, ns, key) => {
      console.log(`Missing translation ${ns} key: ${key}`);
      BugsnagManager.notify(
        new Error(`Missing translation ${ns} key: ${key}`),
        {
          context: `Missing translation ${ns} key: ${key}`,
        }
      );
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, 
    },
  });
