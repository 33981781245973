import BuyNowService from "../../components/Services/BuyNowService";
import i18next from 'i18next';
export const FETCH_LANGUAGE_DATA_BEGIN = "FETCH_LANGUAGE_DATA_BEGIN";
export const FETCH_LANGUAGE_DATA_SUCCESS = "FETCH_LANGUAGE_DATA_SUCCESS";
export const FETCH_LANGUAGE_DATA_ERROR = "FETCH_LANGUAGE_DATA_ERROR";
export const RESET_LANGUAGE_DATA = "RESET_LANGUAGE_DATA";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const SET_LOCATION_CODES = "SET_LOCATION_CODES";

function fetchLanguageDataBegin() {
  return {
    type: FETCH_LANGUAGE_DATA_BEGIN,
  };
}

function fetchLanguageDataSuccess(data, locationId) {
  return {
    type: FETCH_LANGUAGE_DATA_SUCCESS,
    payload: data,
    meta: {
      locationId,
    },
  };
}

function fetchLanguageDataError() {
  return {
    type: FETCH_LANGUAGE_DATA_ERROR,
  };
}

export function resetLanguageData() {
  return {
    type: RESET_LANGUAGE_DATA,
  };
}

export function setLocationId(locationId) {
  return {
    type: SET_LOCATION_ID,
    payload: locationId,
  };
}
export function setLocationCodes(codes) {
  return {
    type: SET_LOCATION_CODES,
    payload: codes,
  };
}

export const fetchLanguageData =
  (locationId = 0) =>
  (dispatch) => {
    dispatch(fetchLanguageDataBegin());
    new BuyNowService()
      .getLanguage("EN", locationId)
      .then((response) => {

        if (locationId === 0) {
          i18next.addResourceBundle('en', 'LANGUAGE_LABELS_DEFAULT', JSON.parse(response.data?.d || "{}"))
        } else {
          i18next.addResourceBundle('en', 'LANGUAGE_LABELS_DETAILS', JSON.parse(response.data?.d || "{}"))
        }

        return new BuyNowService().getLanguage("FR", locationId);
      })
      .then((response) => {
        if (locationId === 0) {
          i18next.addResourceBundle('fr', 'LANGUAGE_LABELS_DEFAULT', JSON.parse(response.data?.d || "{}"));
        } else {
          i18next.addResourceBundle('fr', 'LANGUAGE_LABELS_DETAILS', JSON.parse(response.data?.d || "{}"));
        }

        dispatch(fetchLanguageDataSuccess(JSON.parse(response.data?.d || "{}"), locationId));
      })
      .catch((error) => {
        console.warn(error);
        dispatch(fetchLanguageDataError());
      });
  };
