import { produce } from "immer";

import {
  HANDLE_MAP_HOVER,
  SET_MAP_STATE,
  SET_SEARCH_LOADING,
  SET_SEARCH_LOTS,
  SET_SEARCH_ALL_LOTS,
  SET_SEARCH_FILTERED_LOTS,
  SET_SEARCH_PRODUCTS,
  SET_SEARCH_FILTERS,
  SET_SEARCH_NO_RESULTS,
  RESET_MAP_STATE,
  SET_DURATIONS,
  SET_LOCATION_DURATIONS,
} from "../actions/search.actions";
import { calculateDefaultZoomLevel } from "../../components/Utils/MapUtils";

const defaultDurations = [
  { label: 'COMPONENTS.LAYOUT.SEGMENT.SEARCHWIDGET.ONEHOUR', minutes: 60 },
  { label: 'COMPONENTS.LAYOUT.SEGMENT.SEARCHWIDGET.TWOHOURS', minutes: 120 },
  { label: 'COMPONENTS.LAYOUT.SEGMENT.SEARCHWIDGET.HALFDAY', minutes: 720 },
  { label: 'COMPONENTS.LAYOUT.SEGMENT.SEARCHWIDGET.FULLDAY', minutes: 1440 },
];

function applyFiltersToLots(filters = {}, lots = [], oneTime, locationFilter) {
  return lots
    .filter((lot) => {
      // If we have a locationName filter coming form the client we want to prioritize it above all over filters.
      if (locationFilter) {
        const filterName = locationFilter?.toLowerCase();
        const lotName = lot?.Name?.toLowerCase();
        return lotName?.includes?.(filterName);
      }
      if (filters.indoorSelfPark) {
        return lot?.Amenities?.includes?.("Indoor Self-Park");
      }
      if (filters.outdoorSelfPark) {
        return lot?.Amenities?.includes?.("Outdoor Self-Park");
      }
      if (filters.valet) {
        return lot?.Amenities?.includes?.("Valet Parking");
      }
      if (filters.open247) {
        return lot?.Amenities?.includes?.("Open 24/7");
      }

      return true;
    })
    .sort((a, b) => {
      if (oneTime) {
        const hasAProduct = a.products?.length;
        const hasBProduct = b.products?.length;

        if (hasAProduct && !hasBProduct) return -1;
        if (hasBProduct && !hasAProduct) return 1;

        if (filters.sortLotsBy === "PriceA") {
          return a.amount - b.amount;
        } else {
          return b.amount - a.amount;
        }
      } else {
        const aPrice = a.Amount;
        const bPrice = b.Amount;
        const hasAmountA = aPrice > 0;
        const hasAmountB = bPrice > 0;

        if (hasAmountA && !hasAmountB) return -1;
        if (hasAmountB && !hasAmountA) return 1;

        if (filters.sortLotsBy === "PriceA") {
          return aPrice - bPrice;
        } else {
          return bPrice - aPrice;
        }
      }
    });
}

const INITIAL_STATE = {
  hoverLocationId: null,
  loading: false,
  noResults: false,
  lots: [],
  allLots: [],
  filteredLots: [],
  products: [],
  durations: defaultDurations,
  locationDurations: defaultDurations,
  filters: {
    filterNum: 0,
    sortLotsBy: "PriceA",
    filterResults: "buyNow",
    indoorSelfPark: false,
    outdoorSelfPark: false,
    valet: false,
    open247: false,
  },
  mapState: {
    isNearMe: false,
    placeIsEstablishment: false,
    zoom: null,
    // this represents the "weighted" center of your search,
    // aka the capital of the country if you were to search the US,
    // as opposed to the "polygon center" which is the exact center
    // of the actual searchable viewport
    searchResultsCenterLat: 0,
    searchResultsCenterLng: 0,
    neLat: 0,
    neLng: 0,
    swLat: 0,
    swLng: 0,
    originalBbox: {
      neLat: 0,
      swLat: 0,
      neLng: 0,
      swLng: 0,
    },
  },
};

export { INITIAL_STATE };
export default produce((draft, action) => {
  switch (action.type) {
    case HANDLE_MAP_HOVER:
      draft.hoverLocationId = action.payload ?? null;
      return draft;
    case SET_SEARCH_LOTS:
      draft.lots = action.payload;
      return draft;
    case SET_SEARCH_ALL_LOTS:
      draft.allLots = action.payload;
      return draft;
    case SET_SEARCH_FILTERED_LOTS:
      draft.filteredLots = applyFiltersToLots(
        draft.filters,
        action.payload,
        action.meta.oneTime,
        action.meta.locationFilter
      );
      return draft;
    case SET_SEARCH_PRODUCTS:
      draft.products = action.payload;
      return draft;
    case SET_SEARCH_LOADING:
      draft.loading = !!action.payload;
      if (draft.loading) {
        draft.noResults = false;
      }
      return draft;
    case SET_SEARCH_NO_RESULTS:
      draft.noResults = !!action.payload;
      return draft;
    case SET_SEARCH_FILTERS:
      const newFilters = {
        ...draft.filters,
        ...action.payload,
      };
      let filterNum = 0;
      if (newFilters.sortLotsBy !== INITIAL_STATE.filters.sortLotsBy) {
        filterNum += 1;
      }
      if (newFilters.filterResults !== INITIAL_STATE.filters.filterResults) {
        filterNum += 1;
      }
      if (newFilters.indoorSelfPark !== INITIAL_STATE.filters.indoorSelfPark) {
        filterNum += 1;
      }
      if (
        newFilters.outdoorSelfPark !== INITIAL_STATE.filters.outdoorSelfPark
      ) {
        filterNum += 1;
      }
      if (newFilters.valet !== INITIAL_STATE.filters.valet) {
        filterNum += 1;
      }
      if (newFilters.open247 !== INITIAL_STATE.filters.open247) {
        filterNum += 1;
      }
      if (newFilters.locationName !== INITIAL_STATE.filters.locationName) {
        filterNum += 1;
      }
      draft.filters = newFilters;
      draft.filters.filterNum = filterNum;
      draft.filteredLots = applyFiltersToLots(
        draft.filters,
        draft.lots,
        action.meta.oneTime,
        action.meta.locationFilter
      );
      return draft;
    case SET_MAP_STATE:
      if (action.meta.clearPrevious) {
        draft.hoverLocationId = null;
        draft.loading = true;
        draft.noResults = false;
        draft.lots = [];
        draft.allLots = [];
        draft.filteredLots = [];
        draft.products = [];
      }
      if (
        action.payload.searchResultsCenterLat &&
        action.payload.searchResultsCenterLng
      ) {
        draft.mapState.searchResultsCenterLat =
          action.payload.searchResultsCenterLat;
        draft.mapState.searchResultsCenterLng =
          action.payload.searchResultsCenterLng;
      }
      if (action.payload.rawBounds) {
        draft.mapState.neLat = parseFloat(
          (action.payload.rawBounds.neLat || 0).toFixed(7)
        );
        draft.mapState.neLng = parseFloat(
          (action.payload.rawBounds.neLng || 0).toFixed(7)
        );
        draft.mapState.swLat = parseFloat(
          (action.payload.rawBounds.swLat || 0).toFixed(7)
        );
        draft.mapState.swLng = parseFloat(
          (action.payload.rawBounds.swLng || 0).toFixed(7)
        );
      } else if (action.payload.bounds) {
        draft.mapState.neLat = parseFloat(
          (action.payload.bounds?.getNorthEast?.()?.lat?.() || 0).toFixed(7)
        );
        draft.mapState.neLng = parseFloat(
          (action.payload.bounds?.getNorthEast?.()?.lng?.() || 0).toFixed(7)
        );
        draft.mapState.swLat = parseFloat(
          (action.payload.bounds?.getSouthWest?.()?.lat?.() || 0).toFixed(7)
        );
        draft.mapState.swLng = parseFloat(
          (action.payload.bounds?.getSouthWest?.()?.lng?.() || 0).toFixed(7)
        );
      }
      if (action.payload.bounds || action.payload.rawBounds) {
        if (action.meta.shouldSaveOriginalBounds) {
          if (!draft.mapState.originalBbox) {
            draft.mapState.originalBbox = {};
          }
          draft.mapState.originalBbox = {
            neLat: draft.mapState.neLat,
            swLat: draft.mapState.swLat,
            neLng: draft.mapState.neLng,
            swLng: draft.mapState.swLng,
          };
        }
        draft.mapState.zoom = action.meta.shouldCalculateDefaultZoom
          ? calculateDefaultZoomLevel({
              placeIsEstablishment: !!action.payload.placeIsEstablishment,
              isNearMe: !!action.payload.isNearMe,
              north: draft.mapState.neLat,
              south: draft.mapState.swLat,
              east: draft.mapState.neLng,
              west: draft.mapState.swLng,
            })
          : action.payload.zoom ?? draft.mapState.zoom;
      }
      draft.mapState.isNearMe = !!action.payload.isNearMe;
      draft.mapState.placeIsEstablishment =
        !!action.payload.placeIsEstablishment;
      return draft;
    case RESET_MAP_STATE:
      draft.mapState = INITIAL_STATE.mapState;
      return draft;
    case SET_DURATIONS:
      draft.durations = action.payload || [];
      return draft;
    case SET_LOCATION_DURATIONS:
      draft.locationDurations = action.payload || [];
      return draft;
    default:
      return draft;
  }
}, INITIAL_STATE);
