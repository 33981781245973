import BugsnagManager from "../../BugsnagManager";
import { t } from"i18next";


export const SET_DISABLE_START_DATE_AND_TIME =
  "SET_DISABLE_START_DATE_AND_TIME";
export const SET_SEARCH_WIDGET_VISIBILITY = "SET_SEARCH_WIDGET_VISIBILITY";
export const SET_IS_FULL_SEARCH_WIDGET = "SET_IS_FULL_SEARCH_WIDGET";
export const SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE =
  "SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE";
export const TOGGLE_TOAST_VISIBILITY = "TOGGLE_TOAST_VISIBILITY";
export const SET_TOAST = "SET_TOAST";

export function toggleToastVisibility() {
  return {
    type: TOGGLE_TOAST_VISIBILITY,
  };
}

export function setSearchWidgetVisible(visible = false) {
  return {
    type: SET_SEARCH_WIDGET_VISIBILITY,
    payload: visible,
  };
}

export function hideSearchWidget() {
  return {
    type: SET_SEARCH_WIDGET_VISIBILITY,
    payload: false,
  };
}

export function setIsFullSearchWidget(isFullSearch = false) {
  return {
    type: SET_IS_FULL_SEARCH_WIDGET,
    payload: isFullSearch,
  };
}

export function setSearchWidgetBackgroundCloseEnable(closeEnabled = false) {
  return {
    type: SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE,
    payload: closeEnabled,
  };
}

export function setDisableStartDateAndTime(disableStartDateAndTime = false) {
  return {
    type: SET_DISABLE_START_DATE_AND_TIME,
    payload: disableStartDateAndTime,
  };
}

export function setToast({ title, message, type, code }) {
  return {
    type: SET_TOAST,
    payload: { title, message, type, code },
  };
}


export function setErrorToast(error, fallback) {
  if (!error) {
    console.warn("No error passed to setErrorToast")
    BugsnagManager.notify(new Error("No error passed to setErrorToast"), {
      context: "No error passed to setErrorToast"
    })
  }
  if (!fallback) {
    console.warn("No fallback passed to setErrorToast")
    BugsnagManager.notify(new Error("No fallback passed to setErrorToast"), {
      context: "No fallback passed to setErrorToast"
    })
  }
  const { SubCode, Code } = JSON.parse(error?.response?.StackTrace || '{}')
  const code = SubCode || Code || error?.response?.code || error.response?.errorCode
  return {
    type: SET_TOAST,
    payload: {
      message: t([`ERROR_MESSAGES:${code}`, fallback || "UNKNOWN_ERROR"]),
      type: "E",
    },
  };
}