import "./styles.scss";
import React from "react";
import backgroundImage from "../../../assets/image/laz_blue_bg.png";
import warningIcon from "../../../assets/image/warning_black.svg";
import LAZButton from "../Segments/LAZButton";
import { useTranslation } from "react-i18next";

function ErrorOverlay() {

  const { t } = useTranslation('common');

  return (
    <div className="error-overlay-container">
      <div className="error-overlay-inner-container">
        <div className="error-overlay-title-container">
          <img
            src={warningIcon}
            alt="warning"
            className="error-overlay-warning-icon"
          />
          <div className="error-overlay-title">
            {t('COMMON.ERROROVERLAY.SOMETHINGWENTWRONG')}
          </div>
        </div>
        <div className="error-overlay-subtitle">
            {t('COMMON.ERROROVERLAY.APOLOGIZEAPPLICATIONUNEXPECTEDERROR')}
        </div>
        <br />
        <div className="error-overlay-subtitle">

           {t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONSTITLE')}
          <ul>
            <li>
              <strong>{t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONREFRESHTITLE')}</strong>: {t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONREFRESH')}
            </li>
            <li>
              <strong>{t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONCLEARCACHETITLE')}</strong>: {t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONCLEARCACHE')}
            </li>
            <li>
              <strong>{t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONCHECKCONNECTIONTITLE')}</strong>: {t('COMMON.ERROROVERLAY.ERRORINSTRUCTIONCHECKCONNECTION')}
            </li>
          </ul>
        </div>
        <br />
        <div className="error-overlay-footer">
          <div className="btn-group w-100">
            <LAZButton
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {t('COMMON.ERROROVERLAY.BUTTONGOHOME')}
            </LAZButton>
          </div>
        </div>
      </div>
      <img
        src={backgroundImage}
        alt="background"
        className="background-full-image"
      />
    </div>
  );
}

export default ErrorOverlay;
