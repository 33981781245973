import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { ConfigProvider } from "antd";
import App from "./App";

import reduxStore from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import moment from "moment";
import ErrorOverlay from "./components/Common/ErrorOverlay";
import BugsnagManager from "./BugsnagManager";

import './i18n'
moment.suppressDeprecationWarnings = true;

const { store, persistor } = reduxStore;
const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
  // onError: function (event) {
  //   try {
  //     if (event?.originalError?.response?.status === 500) {
  //       if (
  //         event?.originalError?.response?.data?.Message &&
  //         event.errors?.[0]
  //       ) {
  //         event.errors[0].errorMessage =
  //           event.originalError.response.data.Message;
  //         event.errors[0].stacktrace?.shift?.();
  //       }
  //       event.addMetadata("500 Response", {
  //         code: event?.originalError?.response?.data?.Message || "?",
  //         payload: event?.originalError?.config?.data || "",
  //         method: event?.originalError?.config?.method || "",
  //         url: event?.originalError?.config?.url || "",
  //         ExceptionType:
  //           event?.originalError?.response?.data?.ExceptionType || "?",
  //         ...JSON.parse(
  //           event?.originalError?.response?.data?.StackTrace || "{}"
  //         ),
  //       });
  //     }
  //   } catch (error) {
  //     console.warn(
  //       "Unable to provide additional 500 status error information",
  //       error
  //     );
  //   }
  // },
});

BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const onError = (error) => {
  console.warn(error);
  BugsnagManager.notify(error, {
    context: "Uncaught top-level error",
  });
};

root.render(
  <ErrorBoundary FallbackComponent={ErrorOverlay} onError={onError}>
    <Provider store={store}>
      <ConfigProvider theme={{ token: { colorPrimary: "#027DBA" } }}>
        <PersistGate loading={<div />} persistor={persistor}>
          <React.Suspense fallback="loading">
            <App />
          </React.Suspense>
        </PersistGate>
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>
);
